import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { Layout } from '../layouts';
import { TagCloud } from '../components';


const PostTags = styled.div`
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 11rem;
    border-radius: 10px;
    position:relative;
    background: ${props => props.theme.colors.background.dark};
    transition: all .3s ease;
    box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);
`;

const PostTagContent = styled.div`
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    color: ${props => props.theme.colors.primary.base};
`;

const PostTagTitleBlock = styled.div`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1rem;
    background: ${props => props.theme.colors.background.light};
`;

const PostTagTitle = styled.h1`
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 400;
    color: ${props => props.theme.colors.primary.light};
`;

const PostTagBlockBottom = styled.div`
    padding: 1rem;
`;

const PostTagList = styled.ul`
    margin-top: 0;
    list-style-type: none;
    a {
        color: ${props => props.theme.colors.link};
        text-decoration: none;
        &:hover {
            color: ${props => props.theme.colors.linkHover};
            text-decoration: underline;
        }
    }
`;

const Tags = ({ data }) => {
    const tags = data.allMarkdownRemark.group;
    return (
        <Layout title="All Tags">
        <PostTags>
            <PostTagContent>
                <PostTagTitleBlock>
                    <PostTagTitle>
                            All Tags
                    </PostTagTitle>
                </PostTagTitleBlock>
                <PostTagBlockBottom>
                    <PostTagList>
                        <TagCloud list={tags} showNum={true} />
                    </PostTagList>
                </PostTagBlockBottom>
            </PostTagContent>
        </PostTags>
    </Layout>
    )
};

export default Tags;

export const query = graphql`
    query AllTags {
        allMarkdownRemark (
            filter: { frontmatter: { published: { eq: true } } }
        ) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`;